@import '../../Design/color.scss';

.searchdetails-card {
  background: #ffffff;
  border: 1px solid #e8e8e8;
  box-sizing: border-box;
  border-radius: 5px;
  max-width: 95%;
  font-family: Muli;
  font-style: normal;
  font-weight: normal;
  cursor: default;
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.1);
}
.title {
  font-size: 18px;
  line-height: 23px;
  color: $color-lilac;
  cursor: pointer;
}
.subtitle {
  font-size: 10px;
  line-height: 13px;
  color: #78797c;
  margin-left: -5px;
}
.content {
  font-size: 14px;
  line-height: 18px;
  color: #5a5a5a;
}
.highlight {
  font-weight: bold;
  background-color: $highlight-yellow;
}
.results-for {
  font-family: Muli;
  font-style: normal;
  font-size: 14px;
  line-height: 18px;
  color: #3c3d3b;
}

.hlt1 {
   font-weight: bold;
  background-color: $highlight-yellow;
}