.filters {
  list-style-type: none;
  padding: 10px 0px 0px 0px;
  border-bottom: 1px solid #e2e2e2;
}
.filter-class {
  background-color: white;
  border-radius: 5px 5px 5px 5px;
}
.filter-list {
  font-family: Rubik;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 14px;
  color: #808285;
  cursor: pointer;
}
.filter-nanobar {
  height: 4px;
  margin-top: 2%;
  margin-bottom: -0.5px;
  background: linear-gradient(90deg, #e8bee1 8.85%, #cd9dc5 91.92%);
}
#filter-value-default {
  color: #808285;
}
#filter-value-active {
  color: #000;
  font-weight: bold;
}
#filter-value-count-active {
  color: #3c3d3b;
  font-weight: 600;
}
#expand-icon {
  float: right;
  color: #4c4c4c;
  font-size: 12px;
}
#filter-value-count-active {
  color: #3c3d3b;
  font-weight: 600;
}
#filter-right-content {
  padding: 5%;
  border-left: 0px solid transparent;
  margin: 0%;
  padding: 8% 5% 7% 5%;
  color: #3c3d3b;
}
#filter-right-content-active {
  border-left: 4px solid #c44e4e;
  padding: 8% 5% 7% 5%;
  margin: 0%;
}
#searched-query {
  font-family: Muli;
  font-size: 13px;
  color: #676666;
  text-align: center;
  border: 1.5px solid #d5a9ce;
  border-radius: 20px;
  margin-left: 8px;
  padding: 0 10px;
  font-weight: normal;
  line-height: 25px;
  cursor: default;
}
.tag-basic-btn {
  font-family: Muli, 'sans-serif';
  font-weight: 700;
  font-size: 14px;
  border-radius: 30px;
  padding: 0px 15px;
  line-height: 2.6;
  display: inline-flex;
}
